//  export const API_URL = 'http://localhost:3100';
// export const USER_API_URL = 'http://localhost:3100';
// export const API_URL = 'https://sagt-user-api.acuizen.com';
// export const USER_API_URL = 'https://sagt-user-api.acuizen.com';

export const API_URL = 'https://dev-sagt-user-api.acuizen.com';
export const USER_API_URL = 'https://dev-sagt-user-api.acuizen.com';


export const INCIDENT_REVIWERER_URL = API_URL + '/users/incident-reviewer';
export const RISKASSESSMENT_LIST = API_URL + '/risk-assessments'
export const RISK_UPDATES = API_URL + '/risk-updates'
export const GET_ALL_USER = API_URL + '/users-all'

// export const USER_API_URL = 'http://localhost:3060';
export const TOOLBOXTALK_LIST = API_URL + '/ra-toolbox-talks';
export const HAZARDS_CATEGOTY = 'https://risk-api.acuizen.com/hazards-categories'
export const HAZARDS = API_URL + 'https://risk-api.acuizen.com/hazards'
export const GET_TEAM_MEMBERS_RISK = API_URL + '/users/ra-team-member'
export const SENT_NOTIFICATION_MAIL = API_URL + '/risk-member-notification'
export const LOCATION1_URL = API_URL + '/location-ones';
export const LOCATION3_URL = API_URL + '/location-threes';
export const LOCATIONS_URL = API_URL + '/location-ones';
export const SUBMIT_URL = API_URL + '/location-ones';
export const CHECKLIST_URL = API_URL + '/checklists';
export const OBS_REVIEWER_ASSIGN = (id) => {
    return API_URL + '/actions/' + id;
}
export const OBS_ACTION_ASSIGN = (id,id1) => {
    return API_URL + '/observation-reports/' + id+'/'+id1;
}
export const OBS_ACTION_ARCHIVE = (id,id1) => {
    return API_URL + '/observation-reports-close/' + id+'/'+id1;
}
export const ACTION_OWNER_LIST = API_URL + '/users/observation-owner';
export const ACTION_REVIEWER_LIST = API_URL + '/users/observation-reviewer';
export const DOCUMENTS_URL = API_URL + '/location-ones';
export const LOCATION_CONFIG_URL = API_URL + '/location-ones';
export const STATIC_URL = USER_API_URL + '/docs';
export const CHECKLIST_LIST_URL = API_URL + '/checklists';
export const USERS_URL = API_URL + '/users';
export const DOCUMENT_CATEGORY = API_URL + '/document-categories';
export const GET_USER_ROLE_BY_MODE = API_URL + '/users/get_users'
export const INTERNAL_USERS_URL = API_URL + '/internal/users';
export const DASHBOARD_KA_ADMIN_DATA = API_URL + '/dashboardka-data'
export const EXTERNAL_USERS_URL = API_URL + '/users/external';
export const AIR_SURVEYORS_URL = API_URL + '/users/air-surveyor';
export const AIR_COST_ESTIMATOR_URL = API_URL + '/users/air-cost-estimator';
export const AIR_GM_OPS_URL = API_URL + '/users/air-gm-ops';
export const AIR_MEDICAL_OFFICER_URL = API_URL + '/users/air-medical-officer';
export const AIR_TRAINEE_URL = API_URL + '/users/air-trainee';
export const AIR_THIRD_PARTY_URL = API_URL + '/users/air-third-party';
export const AIR_COST_REVIEWER_URL = API_URL + '/users/air-cost-reviewer';
export const AIR_FINANCE_URL = API_URL + '/users/air-finance';
export const AIR_DUTY_ENGINEER_MANAGER_URL = API_URL + '/users/air-duty-engineer-manager';
export const AIR_SECURITY_URL = API_URL + '/users/air-security';
export const AIR_ENGINEER_URL = API_URL + '/users/air-engineer';
export const ASSIGNED_UNIT_URL = API_URL + '/add-ku-user-threes'
export const ASSIGNED_CHECKLIST_URL = API_URL + '/add-checklist-user'
export const GROUP_ASSIGNED_CHECKLIST_URL = API_URL + '/add-checklist-group-user'
export const TIER1_URL = API_URL + '/areas';
export const ASSET_TIER1_URL = API_URL + '/tier-ones';
export const ASSET_TIER2_URL = API_URL + '/tier-twos';
export const TITLE_URL = API_URL + '/title-configs';
export const DYNAMIC_TITLES_URL = API_URL + '/dynamic-titles';
export const OBSERVATION_REPORT_URL = USER_API_URL + '/observation-reports';
export const REPORT_INCIDENT_URL = USER_API_URL + '/report-incidents';
export const REPORT_INCIDENT_REVIEW_URL = USER_API_URL + '/report-incidents-review';
export const FILE_URL = API_URL + '/files';
export const CURRENT_USER_URL = API_URL + '/users/me';
export const GROUP_URL = API_URL + '/groups';
export const GROUP_ASSIGNED_UNIT_URL = API_URL + '/add-ku-group-user-threes'
export const ACTION_URL = API_URL + '/actions';
export const ALL_ACTION_URL = API_URL + '/all-actions';
export const MY_ACTION_URL = API_URL + '/my-actions';
export const ASSIGN_USER_GROUP = API_URL + '/group-users-add';
export const DOCUMENT_URL = API_URL + '/documents';
export const ASSIGNED_DOCUMENT_URL = API_URL + '/add-document-user';
export const WORKING_GROUP_URL = API_URL + '/working-groups';
export const WEATHER_CONDITION_URL = API_URL + '/weather-conditions';
export const GHS_TWO_URL = API_URL + '/ghs-twos';
export const GHS_ONE_URL = API_URL + '/ghs-ones';
export const SURFACE_TYPE_URL = API_URL + '/surface-types';
export const SURFACE_CONDITION_URL = API_URL + '/surface-conditions';
export const EQUIPMENT_CATEGORIES_URL = API_URL + '/equipment-categories';
export const DOCUMENTUSER = API_URL + '/user-list-documents';
export const GET_USER_BY_ROLES_URL = API_URL + '/users/get_users';
export const NEW_TEST_CASE_URL = API_URL + '/new-test-cases';
export const TEST_CASE_URL = API_URL + '/test-cases';
export const USER_LIST_PROGRAM = API_URL + '/user-list-programs'
export const REVIEWER_AIR_URL = API_URL + '/air-reports-reviewer';
export const AIR_INITIATE_INVESTIGATION_URL = API_URL + '/air_initiate_inevstigation';
export const AIR_CLASSIFIED_GROUPS_URL = API_URL + '/air-reports-classified-groups';
export const DRAFT_RA = API_URL + '/risk-assessments_draft'


export const AIR_HOD_FINANCE_RETURN_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-hod-finance-return/' + id + '/' + actionId
}


export const DRAFT_RA_WITH_ID = (id) => {
    return API_URL + '/risk-assessments_draft/' + id;
}

export const RA_ACTION_WITH_ID = (id) => {
    return API_URL + '/risk-assessments_action/' + id;
}

export const TEST_CASE_WITH_ID_URL = (id) => {
    return API_URL + '/test-cases/' + id;
}
export const NEW_TEST_CASE_WITH_ID_URL = (id) => {
    return API_URL + '/new-test-cases/' + id;
}

export const OBSERVATION_REVIEWER_LIST_URL = API_URL + '/action-reviewer-list';

export const DEPARTMENT_URL = API_URL + '/weather-conditions';
export const LIGHTING_URL = API_URL + '/lightings';

export const PPES_URL = API_URL + '/ppes';
export const INJURY_URL = API_URL + '/ehs-roles';

export const UPLOAD_PDF = API_URL + '/upload-pdf'
export const GENERAL_USER_URL = API_URL + '/general-users';

export const GENERAL_GROUP_URL = API_URL + '/general-groups';
export const GENERAL_GROUP_BY_ID = (id) => {
    return API_URL + '/general-groups/' + id;
}
export const DOCUMENT_CATEGORY_WITH_ID = (id) => {
    return API_URL + '/document-categories/' + id;;
}
export const DOCUMENT_WITH_ID = (id) => {
    return API_URL + '/documents/' + id;;
}
export const DOWNLOAD_DOCS_URL = (files) => {
    return API_URL + '/docs/' + files;
}
export const REPORT_INCIDENT_INVESTIGATION_URL_WITH_ID = (id) => {
    return API_URL + '/report-incidents-investigation/' + id;
}
export const REPORT_INCIDENT_ACTIONS_WITH_ID = (id) => {
    return API_URL + '/report-incident/actions/' + id;
}

export const REPORT_INCIDENT_INVESTIGATION_SAVE_URL_WITH_ID = (id) => {
    return API_URL + '/save-report-incidents-investigation/' + id;
}
export const OBSERVATION_REPORT_BY_OTHERS_URL = API_URL + '/observation-reports-by-locations';
export const GROUP_ASSIGNED_DOCUMENT_URL = API_URL + '/add-document-group-user'

export const RISK_UPDATE_WITH_ID_URL = (id) => {
    return API_URL + '/risk-assessments/' + id + '/risk-updates';
}
export const TOOLBOX_WITH_ID_URL = (id) => {
    return API_URL + '/ra-toolbox-talks/' + id;
}
export const DOCUMENTS_WITH_ID_URL = (id) => {
    return API_URL + '/documents/' + id;
}

export const PERMIT_REPORTS = API_URL + '/permit-reports';
export const PERMIT_REPORT_WITH_ID = (id) => {
    return API_URL + '/permit-reports/' + id;
}
export const ALL_LOCATIONTHREE_LOCATIONFOUR_URL = (id) => {
    return API_URL + '/all-location-threes/' + id + '/all-location-fours';
}
export const ALL_PERMITS_URL = API_URL + '/permit-reports';
export const UPDATE_TRUCK_STATUS_WITH_ID_URL = (id) => {
    return API_URL + '/air-reports-truck-status/' + id;
}
export const ASSIGNED_DOCUMENT_BY_ID = (id) => {
    return API_URL + '/user-document-userid/' + id;
}
export const KNOWLEDGE_SESSION_USER = (id) => {
    return API_URL + '/knowledge-session-userid/' + id
}
export const KNOWLEDGE_SESSION_KU = (id) => {
    return API_URL + '/knowledge-session-threeid/' + id
}
export const CHECKLIST_SESSION_USER = (id) => {
    return API_URL + '/checklist-session-userid/' + id
}
export const CHECKLIST_SESSION_KU = (id) => {
    return API_URL + '/checklist-session-threeid/' + id
}
export const GROUP_ASSIGNED_DOCUMENT_BY_ID = (id) => {
    return API_URL + '/group-document-groupid/' + id;
}
export const GROUP_ASSIGNED_AREA_BY_id = (id) => {
    return API_URL + '/group-tier-threes-groupid/' + id;
}
export const GROUP_USERS_WITH_ID = (id) => {
    return API_URL + '/group-user-group/' + id;
}
export const GROUP_ASSIGNED_CHECKLIST_BY_ID = (id) => {
    return API_URL + '/group-checklist-groupid/' + id;
}
export const CHECKLIST_WITH_ID_URL = (id) => {
    return API_URL + '/checklists/' + id;
}
export const GROUP_WITH_ID_URL = (id) => {
    return API_URL + '/groups/' + id;
}
export const K_TIER1_TIER2_URL = (id) => {
    return API_URL + '/areas/' + id + '/topics';
}
export const ASSIGNED_CHECKLIST_BY_ID = (id) => {
    return API_URL + '/user-checklist-userid/' + id;
}
export const ASSIGNED_AREA_BY_id = (id) => {
    return API_URL + '/user-tier-threes-userid/' + id;
}

export const K_TIER2_TIER3_URL = (id) => {
    return API_URL + '/topics/' + id + '/units';
}
export const ACTION_WITH_ID_URL = (id) => {
    return API_URL + '/actions/' + id
}

export const CREATE_STEP_TITLE = (id) => {
    return API_URL + '/units/' + id + '/step-titles';
}
export const STEP_WITH_ID_URL = (id) => {
    return API_URL + '/step-titles/' + id;
}
export const AIR_URL = API_URL + '/air-reports';
export const AIR_REVIEW_URL = API_URL + '/air-review-reports';
export const AIR_INVESTIGATE_URL = API_URL + '/air-investigate-reports';

export const AIR_WITH_ID_URL = (id) => {
    return API_URL + '/air-reports/' + id
}

export const PUBLIC_AIR_WITH_ID_URL = (id) => {
    return API_URL + '/public-air-reports/' + id
}

export const ALL_AIR_REPORTS_URL = API_URL + '/all-air-reports';

export const AIR_REVIEW_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-reviewer-reports/' + id + '/' + actionId
}

export const AIR_REVIEWER_SUBMIT_WITHOUT_ACTION_WITH_ID_URL = (id) => {
    return API_URL + '/air-reviewer-reports-without-action/' + id;
}

export const AIR_REVIEWER_RETURN_WITHOUT_ACTION_WITH_ID_URL = (id) => {
    return API_URL + '/air-review-return-without-action/' + id;
}
export const AIR_INITIATE_INVESTIGATION_WITH_ID_URL = (id) => {
    return API_URL + '/air-initiate-investigation/' + id;
}


export const DOC_REVIEWER_SET_BY_CREATOR = API_URL + '/documents-creator-assign'

export const DOC_APPROVER_SET_BY_REVIEWER = API_URL + '/documents-reviewer-assign'


export const DOC_APPROVER_SET_BY_APPROVER = API_URL + '/documents-final-approver'
export const DOC_RETURN_SET_BY_APPROVER = API_URL + '/documents-return-assign'


export const AIR_MEDICAL_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-medical-reports/' + id + '/' + actionId
}


export const AIR_MEDICAL_WORK_REPORT_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-medical-work-reports/' + id + '/' + actionId
}



export const AIR_ENGINEER_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-engineer-reports/' + id + '/' + actionId
}

export const AIR_APPROVE_REPORTS_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-reports-approve/' + id + '/' + actionId
}


export const AIR_APPROVE_REPORTS_SECOND_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-reports-approve-second/' + id + '/' + actionId
}



export const AIR_INVESTIGATE_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-investigator-reports/' + id + '/' + actionId
}

export const AIR_REVIEW_RETURN_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-review-return/' + id + '/' + actionId
}



export const AIR_GMOPS_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-gmops-reports/' + id + '/' + actionId
}


export const AIR_TRAINEE_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-trainee-reports/' + id + '/' + actionId
}

export const AIR_COST_ESTIMATION_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-cost-estimator-reports/' + id + '/' + actionId
}

export const AIR_DUTY_MANAGER_ESTIMATION_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-duty-manager-estimator-reports/' + id + '/' + actionId
}


export const AIR_HOD_REVIEW_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-hod-review/' + id + '/' + actionId
}

export const AIR_COST_REVIEWER_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-cost-reviewer-reports/' + id + '/' + actionId
}

export const AIR_COST_REVIEWER_FINAL_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-cost-reviewer-reports-final/' + id + '/' + actionId
}


export const AIR_COST_REVIEWER_RETURN_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-cost-reviewer-return-reports/' + id + '/' + actionId
}

export const AIR_FINANCER_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-financer-reports/' + id + '/' + actionId
}

export const AIR_SURVEYOR_WITH_ID_URL = (id, actionId) => {
    return API_URL + '/air-surveyor-reports/' + id + '/' + actionId
}

export const AIR_DRIVERS_WITH_ID_URL = (id) => {
    return API_URL + '/air-reports/' + id + '/drivers';
}

export const AIR_UPDATE_DOCUMENTS_WITH_ID_URL = (id) => {
    return API_URL + '/air-reports-update-documents/' + id;
}



export const RISK_WITH_ID_URL = (id) => {
    return API_URL + '/risk-assessments/' + id
}
export const OBSERVATION_REPORT_WITH_ID = (id) => {
    return API_URL + '/observation-reports/' + id;
}


export const REPORT_INCIDENT_URL_WITH_ID = (id) => {
    return API_URL + '/report-incidents/' + id;
}

export const REPORT_INCIDENT_REVIEW_URL_WITH_ID = (id) => {
    return API_URL + '/report-incidents-review/' + id;
}

export const DYNAMIC_TITLES_WITH_ID = (id) => {
    return API_URL + '/dynamic-titles/' + id;
}

export const USERS_URL_WITH_ID = (id) => {
    return API_URL + '/users/' + id;
}

export const LOCATION1_WITH_ID_URL = (id) => {
    return API_URL + '/location-ones/' + id;
}

export const GMS1_URL = API_URL + '/ghs-ones';

export const GMS1_WITH_ID_URL = (id) => {
    return API_URL + '/ghs-ones/' + id;
}


export const WORK_ACTIVITIES_URL = API_URL + '/work-activities';

export const WORK_ACTIVITIES_WITH_ID_URL = (id) => {
    return API_URL + '/work-activities/' + id;
}


export const EHS_ROLE_URL = API_URL + '/ehs-roles';

export const EHS_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/ehs-roles/' + id;
}


export const EPTW_ROLE_URL = API_URL + '/eptw-roles';

export const EPTW_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/eptw-roles/' + id;
}

export const INCIDENT_ROLE_URL = API_URL + '/incident-roles';

export const INCIDENT_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/incident-roles/' + id;
}

export const INSPECTION_ROLE_URL = API_URL + '/inspection-roles';

export const INSPECTION_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/inspection-roles/' + id;
}

export const PLANT_ROLE_URL = API_URL + '/plant-roles';

export const PLANT_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/plant-roles/' + id;
}


export const GMS1_GMS2_URL = (id) => {
    return API_URL + '/ghs-ones/' + id + '/ghs-twos';
}


export const GMS2_WITH_ID_URL = (id) => {
    return API_URL + '/ghs-twos/' + id;
}

export const OBSERVATION_REVIEWER_SUBMIT_URL = (id) => {
    return API_URL + '/actions/' + id;
}
export const OBSERVATION_APPROVE_URL = (id) => {
    return API_URL + '/actions/' + id;
}
export const OBSERVATION_REJECT_LIST_URL = (id) => {
    return API_URL + '/actions/' + id;
}
export const LOCATION_TIER1_URL = (id) => {
    return API_URL + '/location-ones/' + id + '/location-twos';
}

export const ASSET_TIER1_TIER2_URL = (id) => {
    return API_URL + '/tier-ones/' + id + '/tier-twos';
}

export const TIER1_TIER2_URL = (id) => {
    return API_URL + '/location-twos/' + id + '/location-threes';
}

export const TIER2_TIER3_URL = (id) => {
    return API_URL + '/location-threes/' + id + '/location-fours';
}

export const TIER3_TIER4_URL = (id) => {
    return API_URL + '/location-fours/' + id + '/location-fives';
}
export const TIER4_TIER5_URL = (id) => {
    return API_URL + '/location-fives/' + id + '/location-sixes';
}

export const EDIT_GMS_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/ghs-ones/' + id;
            break;
        case 'tier2':

            return API_URL + '/ghs-twos/' + id;
            break;

        default: break;
    }
}
export const EDIT_ASSET_TIER_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/tier-ones/' + id;
            break;
        case 'tier2':

            return API_URL + '/tier-twos/' + id;
            break;


        default: break;
    }
}

export const EDIT_TIER_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/location-twos/' + id;
            break;
        case 'tier2':

            return API_URL + '/location-threes/' + id;
            break;
        case 'tier3':

            return API_URL + '/location-fours/' + id;
            break;
        case 'tier4':

            return API_URL + '/location-fives/' + id;
            break;

        case 'tier5':

            return API_URL + '/location-sixes/' + id;
            break;

        default: break;
    }
}
export const K_EDIT_TIER_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/areas/' + id;
            break;
        case 'tier2':

            return API_URL + '/topics/' + id;
            break;
        case 'tier3':

            return API_URL + '/units/' + id;
            break;
        case 'tier4':

            return API_URL + '/location-fives/' + id;
            break;

        case 'tier5':

            return API_URL + '/location-sixes/' + id;
            break;

        default: break;
    }
}

export const AUDIT_ACTION_PLAN_REVIEWER = API_URL + '/users/audit_action_plan_reviewer';
export const AUDIT_FINDINGS_ASSIGN_ACTION_URL = (id) => { return API_URL + '/audit-findings-assign-action/' + id };
export const AUDIT_FINDINGS_ACTION_URL = (id) => { return API_URL + '/audit-findings/action/' + id };